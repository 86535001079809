import { makeDateString } from "../helpers";
import {
  MeetingTaskList,
  MeetingTaskListsView,
  MeetingTaskListView,
} from "../masterbigsystem";

export interface EngineeringActivity {
  id: number;
  pic: any | null;
  customer: any | null;
  description: any | null;
  date: any | null;
  tasks: Task[];
  type: string | null;
  fromCache: Date | null;
  toCache: Date | null;
  extJobId: number | null;
  extInquiryId: number | null;
  extPanelCodeId: number | null;
  extPurchaseOrderId: number | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
}

export interface Task {
  id: number;
  description: string | null;
  engineeringActivity: any | null;
  engineeringActivityId: number | null;
  from: Date | null;
  to: Date | null;
  inCharges: InCharge[];
  hours: number | null;
  completedDateSpv: Date | null;
  completedDatePic: Date | null;
  completedDateManager: Date | null;
  completedByPicId: number | null;
  completedBySpvId: number | null;
  completedByManagerId: number | null;
  remark: string | null;
  extPanelCodeId: number | null;
  deletedAt: Date | null;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface InCharge {
  id: number;
  pic: any | null;
  customer: any | null;
  description: any | null;
  date: Date | null;
  task: any | null;
  taskId: number | null;
  extUserId: number | null;
  picName: any | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
}

export class EngineeringActivityConvert {
  public static fromJson(json: any): EngineeringActivity[] {
    try {
      // If json is already an array, process it directly
      const activities = Array.isArray(json) ? json : json.data || [];

      return activities.map((activity: any) => ({
        id: activity.id,
        pic: activity.pic ?? null,
        customer: activity.customer ?? null,
        description: activity.description ?? null,
        date: activity.date ?? null,
        tasks: (activity.tasks || []).map((task: any) => ({
          id: task.id,
          description: task.description ?? null,
          engineeringActivity: task.engineeringActivity ?? null,
          engineeringActivityId: task.engineeringActivityId ?? null,
          from: task.from ? new Date(task.from) : null,
          to: task.to ? new Date(task.to) : null,
          inCharges: (task.inCharges || []).map((inCharge: any) => ({
            id: inCharge.id,
            pic: inCharge.pic ?? null,
            customer: inCharge.customer ?? null,
            description: inCharge.description ?? null,
            date: inCharge.date ?? null,
            task: inCharge.task ?? null,
            taskId: inCharge.taskId ?? null,
            extUserId: inCharge.extUserId ?? null,
            picName: inCharge.picName ?? null,
            createdAt: inCharge.createdAt ? new Date(inCharge.createdAt) : null,
            updatedAt: inCharge.updatedAt ? new Date(inCharge.updatedAt) : null,
            deletedAt: inCharge.deletedAt ? new Date(inCharge.deletedAt) : null,
          })),
          hours: task.hours ?? null,
          completedDateSpv: task.completedDateSpv
            ? new Date(task.completedDateSpv)
            : null,
          completedDatePic: task.completedDatePic
            ? new Date(task.completedDatePic)
            : null,
          completedDateManager: task.completedDateManager
            ? new Date(task.completedDateManager)
            : null,
          completedByPicId: task.completedByPicId ?? null,
          completedBySpvId: task.completedBySpvId ?? null,
          completedByManagerId: task.completedByManagerId ?? null,
          remark: task.remark ?? null,
          extPanelCodeId: task.extPanelCodeId ?? null,
          deletedAt: task.deletedAt ? new Date(task.deletedAt) : null,
          createdAt: task.createdAt ? new Date(task.createdAt) : null,
          updatedAt: task.updatedAt ? new Date(task.updatedAt) : null,
        })),
        type: activity.type ?? null,
        fromCache: activity.fromCache ? new Date(activity.fromCache) : null,
        toCache: activity.toCache ? new Date(activity.toCache) : null,
        extJobId: activity.extJobId ?? null,
        extInquiryId: activity.extInquiryId ?? null,
        extPanelCodeId: activity.extPanelCodeId ?? null,
        extPurchaseOrderId: activity.extPurchaseOrderId ?? null,
        createdAt: activity.createdAt ? new Date(activity.createdAt) : null,
        updatedAt: activity.updatedAt ? new Date(activity.updatedAt) : null,
        deletedAt: activity.deletedAt ? new Date(activity.deletedAt) : null,
      }));
    } catch (error) {
      console.error("Failed to parse engineering activities:", error);
      return [];
    }
  }

  public static toJson(value: EngineeringActivity[]): string {
    return JSON.stringify(value);
  }

  public static toWo(activities: EngineeringActivity[]): MeetingTaskListsView {
    const listWo: MeetingTaskListView[] = activities.map(
      (activity: EngineeringActivity) => {
        const totalTimeActivity = activity.tasks.reduce(
          (acc, task) => acc + (task.hours || 0),
          0
        );
        return {
          taskList: {
            masterJavaBaseModel: {
              createdAt: activity.createdAt
                ? activity.createdAt.toISOString()
                : undefined,
              updatedAt: activity.updatedAt
                ? activity.updatedAt.toISOString()
                : undefined,
              // id: `${activity.id}`,
            },
            name: activity.description || undefined,
            extInChargeId: activity.pic ? String(activity.pic) : undefined,
            extCustomerId: activity.customer
              ? String(activity.customer)
              : undefined,
            extJobId: activity.extJobId ? String(activity.extJobId) : undefined,
            extPanelCodeId: activity.extPanelCodeId
              ? String(activity.extPanelCodeId)
              : undefined,
            extPurchaseOrderId: activity.extPurchaseOrderId
              ? String(activity.extPurchaseOrderId)
              : undefined,
            totalTimeHours: totalTimeActivity || undefined,
            meetingTasks: activity.tasks.map((task: Task) => ({
              masterJavaBaseModel: {
                createdAt: task.createdAt
                  ? task.createdAt.toISOString()
                  : undefined,
                updatedAt: task.updatedAt
                  ? task.updatedAt.toISOString()
                  : undefined,
                // id: `${task.id}`,
              },
              description: task.description || undefined,
              durationMins: task.hours ? task.hours * 60 : undefined,
              start: task.from ? makeDateString(task.from) : undefined,
              deadline: task.to ? makeDateString(task.to) : undefined,
              target: task.to ? makeDateString(task.to) : undefined,
              meetingTaskTargetDates: [
                {
                  date: task.to ? makeDateString(task.to) : undefined,
                },
              ],
              meetingTaskInCharges: task.inCharges.map(
                (inCharge: InCharge) => ({
                  extUserId: inCharge.extUserId
                    ? String(inCharge.extUserId)
                    : undefined,
                  totalTimeHoursTask: task.hours || undefined,
                  totalTimeHours: totalTimeActivity || undefined,
                  masterJavaBaseModel: {
                    createdAt: inCharge.createdAt
                      ? inCharge.createdAt.toISOString()
                      : undefined,
                    updatedAt: inCharge.updatedAt
                      ? inCharge.updatedAt.toISOString()
                      : undefined,
                    // id: `${inCharge.id}`,
                  },
                })
              ),
              status:
                task.completedDatePic && task.completedDateSpv
                  ? "COMPLETED"
                  : "OUTSTANDING",
              extPicCompletedDate: task.completedDatePic
                ? makeDateString(task.completedDatePic)
                : undefined,
              completedDate: task.completedDateSpv
                ? makeDateString(task.completedDateSpv)
                : undefined,
              remark: task.remark || undefined,
            })),
          },
        } as MeetingTaskListView;
      }
    );

    return MeetingTaskListsView.fromPartial({
      taskLists: listWo,
    });
  }

  public static fromJsonToWo(json: any): MeetingTaskListsView {
    const activities = Array.isArray(json) ? json : json.data || [];

    const listWo: MeetingTaskListView[] = activities.map((activity: any) => {
      const totalTimeActivity = (activity.tasks || []).reduce(
        (acc: number, task: any) => acc + (task.hours || 0),
        0
      );
      return {
        taskList: {
          masterJavaBaseModel: {
            createdAt: activity.createdAt
              ? new Date(activity.createdAt).toISOString()
              : undefined,
            updatedAt: activity.updatedAt
              ? new Date(activity.updatedAt).toISOString()
              : undefined,
            // id: `${activity.id}`,
          },
          name: activity.description || undefined,
          extInChargeId: activity.pic ? String(activity.pic) : undefined,
          extCustomerId: activity.customer
            ? String(activity.customer)
            : undefined,
          extJobId: activity.extJobId ? String(activity.extJobId) : undefined,
          extPanelCodeId: activity.extPanelCodeId
            ? String(activity.extPanelCodeId)
            : undefined,
          extPurchaseOrderId: activity.extPurchaseOrderId
            ? String(activity.extPurchaseOrderId)
            : undefined,
          totalTimeHours: totalTimeActivity || undefined,
          meetingTasks: (activity.tasks || []).map((task: any) => ({
            masterJavaBaseModel: {
              createdAt: task.createdAt
                ? new Date(task.createdAt).toISOString()
                : undefined,
              updatedAt: task.updatedAt
                ? new Date(task.updatedAt).toISOString()
                : undefined,
              // id: `${task.id}`,
            },
            description: task.description || undefined,
            durationMins: task.hours ? task.hours * 60 : undefined,
            start: task.from ? makeDateString(new Date(task.from)) : undefined,
            deadline: task.to ? makeDateString(new Date(task.to)) : undefined,
            target: task.to ? makeDateString(new Date(task.to)) : undefined,
            meetingTaskTargetDates: [
              {
                date: task.to ? makeDateString(new Date(task.to)) : undefined,
              },
            ],
            meetingTaskInCharges: (task.inCharges || []).map(
              (inCharge: any) => ({
                extUserId: inCharge.extUserId
                  ? String(inCharge.extUserId)
                  : undefined,
                totalTimeHoursTask: task.hours || undefined,
                totalTimeHours: totalTimeActivity || undefined,
                masterJavaBaseModel: {
                  createdAt: inCharge.createdAt
                    ? new Date(inCharge.createdAt).toISOString()
                    : undefined,
                  updatedAt: inCharge.updatedAt
                    ? new Date(inCharge.updatedAt).toISOString()
                    : undefined,
                  // id: `${inCharge.id}`,
                },
              })
            ),
            status:
              task.completedDatePic && task.completedDateSpv
                ? "COMPLETED"
                : "OUTSTANDING",
            extPicCompletedDate: task.completedDatePic
              ? makeDateString(new Date(task.completedDatePic))
              : undefined,
            completedDate: task.completedDateSpv
              ? makeDateString(new Date(task.completedDateSpv))
              : undefined,
            remark: task.remark || undefined,
          })),
        },
      } as MeetingTaskListView;
    });

    return MeetingTaskListsView.fromPartial({
      taskLists: listWo,
    });
  }
}

import React from 'react';
import { PurchaseRequestDetail } from '../../models/model';
import { PpicJob, PpicJobs } from "../../masterbigsystem";
import { intlFormat } from '../../helpers';

export const matchesJobStatus = (extPurchaseRequest: PurchaseRequestDetail, selectedJobStatus:  number | null, jobs: PpicJobs) => {
    const job = jobs.jobs.find(j => `${j.masterJavaBaseModel?.id}` === `${extPurchaseRequest.jobId}`);
    if (!job) return false;

    const closingDate = job.closingDate;

    if (selectedJobStatus === null) return true;
    if (selectedJobStatus === 1) return closingDate !== null && closingDate !== "" && closingDate !== undefined;

    return closingDate === null || closingDate === "" || closingDate === undefined;
};


const DetailedReportTable: React.FC<{ data: PurchaseRequestDetail[], jobs: PpicJobs, prDetailJob: PpicJob | null, selectedJobStatus: number | null }> = ({ data, jobs, prDetailJob, selectedJobStatus }) => {

    const columnStyles = {
        xs: 'w-16',
        sm: 'w-24',
        md: 'w-32',
        lg: 'w-40',
        xl: 'w-48'
    } as const;

    const headerCellStyle = 'bg-gray-900 text-white px-1 py-0.5 text-sm font-semibold sticky top-0 z-10';


    const baseCellStyle = 'p-2 text-sm';

    return (
        <div className="border overflow-auto border-gray-200 rounded" style={{ height: "65vh", resize: "vertical" }}>
            <div className="">
                <div className="">
                    <table className="w-full table-sm table-bordered" style={{ maxWidth: "100%" }}>
                        <thead className="bg-dark text-light">
                            <tr>
                                <th className={`${headerCellStyle} ${columnStyles.xs}`}>#</th>
                                <th className={`${headerCellStyle} ${columnStyles.lg}`}>Job</th>
                                <th className={`${headerCellStyle} ${columnStyles.sm}`}>PR Number</th>
                                <th className={`${headerCellStyle} ${columnStyles.md}`}>PR Date</th>
                                <th className={`${headerCellStyle} ${columnStyles.md}`}>Request From</th>
                                <th className={`${headerCellStyle} ${columnStyles.xl}`}>Purpose</th>
                                <th className={`${headerCellStyle} ${columnStyles.md}`}>PR Target</th>
                                <th className={`${headerCellStyle} ${columnStyles.sm}`}>PO#</th>
                                <th className={`${headerCellStyle} ${columnStyles.md}`}>PO Date</th>
                                <th className={`${headerCellStyle} ${columnStyles.sm}`}>MFR</th>
                                <th className={`${headerCellStyle} ${columnStyles.md}`}>Part Num</th>
                                <th className={`${headerCellStyle} ${columnStyles.xl}`}>Part Desc</th>
                                <th className={`${headerCellStyle} ${columnStyles.xs} text-right`}>Qty PR</th>
                                <th className={`${headerCellStyle} ${columnStyles.xs} text-right`}>Qty PO</th>
                                <th className={`${headerCellStyle} ${columnStyles.xs} text-right`}>Qty RR</th>
                                <th className={`${headerCellStyle} ${columnStyles.xs} text-right`}>Qty Balance</th>
                                <th className={`${headerCellStyle} ${columnStyles.sm}`}>RR Number</th>
                                <th className={`${headerCellStyle} ${columnStyles.md}`}>RR Date</th>
                                <th className={`${headerCellStyle} ${columnStyles.md}`}>Target Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {data.map((item: PurchaseRequestDetail, index: number) => {
                            const job = jobs.jobs.find(
                                j => `${j?.masterJavaBaseModel?.id}` === `${item?.jobId}`
                            ); */}
                            {data
                                .filter((item: PurchaseRequestDetail) =>
                                    prDetailJob ? `${item.jobId}` === `${prDetailJob.masterJavaBaseModel?.id}` : true
                                ).filter((item: PurchaseRequestDetail) =>
                                    matchesJobStatus(item,  selectedJobStatus , jobs)
                                )
                                .map((item: PurchaseRequestDetail, index: number) => {
                                    const job = jobs.jobs.find(
                                        j => `${j?.masterJavaBaseModel?.id}` === `${item?.jobId}`
                                    );

                                    return (
                                        <tr key={index} className="border-t border-gray-100">
                                            <td className={baseCellStyle}>{index + 1}</td>
                                            <td className={`${baseCellStyle} font-medium`}>{job?.name || '-'}</td>
                                            <td className={baseCellStyle}>{`${item.prNumber}${item.prNumberSeq}`}</td>
                                            <td className={baseCellStyle}>
                                                {intlFormat({
                                                    date: item.prDate,
                                                    dateStyle: "medium"
                                                })}
                                            </td>
                                            <td className={baseCellStyle}>{item.requestFrom}</td>
                                            <td className={baseCellStyle}>{item.purpose}</td>
                                            <td className={baseCellStyle}>
                                                {intlFormat({
                                                    date: item.prTarget,
                                                    dateStyle: "medium"
                                                })}
                                            </td>
                                            <td className={baseCellStyle}>{`${item.poNumber}${item.poNumberSeq}`}</td>
                                            <td className={baseCellStyle}>
                                                {intlFormat({
                                                    date: item.poDate,
                                                    dateStyle: "medium"
                                                })}
                                            </td>
                                            <td className={baseCellStyle}>{item.mfr}</td>
                                            <td className={baseCellStyle}>{item.partNum}</td>
                                            <td className={baseCellStyle}>{item.partDesc}</td>
                                            <td className={`${baseCellStyle} text-right`}>{item.qtyPr}</td>
                                            <td className={`${baseCellStyle} text-right`}>{item.qtyPos}</td>
                                            <td className={`${baseCellStyle} text-right`}>{item.qtyRr}</td>
                                            <td className={`${baseCellStyle} text-right`}>{item.qtyBalance}</td>
                                            <td className={baseCellStyle}>{item.rrNum}</td>
                                            <td className={baseCellStyle}>
                                                {intlFormat({
                                                    date: item.rrDate,
                                                    dateStyle: "medium"
                                                })}
                                            </td>
                                            <td className={`${baseCellStyle} ${item.targetDateNew === item.prTarget
                                                ? "bg-gray-900 text-white"
                                                : ""
                                                }`}>
                                                {intlFormat({
                                                    date: item.targetDateNew,
                                                    dateStyle: "medium"
                                                })}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default DetailedReportTable;
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  TaskListInfo,
  fetchPanelCodeDepartmentTemplateItemsProto,
  fetchWorkOrdersProtoMongo,
  filterWorkOrderByDateRange,
  getChromaColor,
  makeDateString,
} from "../../../helpers";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  MeetingTaskListView,
  MeetingTaskListsView,
  PpicDepartmentTemplates,
  PpicIntegrationModules,
  PpicJobs,
  PpicMachinePrograms,
  PpicPanelCodeDepartmentTemplateItems,
  PpicRcemDepartments,
  PpicSimplifiedProcessTypes,
} from "../../../masterbigsystem";
import { ExtDepartment, ExtUser, Machine } from "../../../models/model";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { CloudDownload } from "@material-ui/icons";
import { DateInput } from "../../ZGlobalComponents/DateInput";
import MyButton from "../../ZGlobalComponents/MyButton";

const WoReportPage = () => {
  const ctx = useContext(AppContext);
  const [, refresh] = useState(false);
  const taskLists = useRef(MeetingTaskListsView.fromPartial({}));
  // const selectedDept = useRef<number | null>(null);
  // const selectedUser = useRef<number | null>(null);

  const [selectedDept, setSelectedDept] = useState<number | null>(null);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);

  const jobsProto = useRef(PpicJobs.fromPartial({}));
  const machines = useRef([] as Machine[]);
  const processTypes = useRef(PpicSimplifiedProcessTypes.fromPartial({}));
  const rcemDepartments = useRef(PpicRcemDepartments.fromPartial({}));
  const integrationModules = useRef(PpicIntegrationModules.fromPartial({}));
  const machinePrograms = useRef(PpicMachinePrograms.fromPartial({}));
  const departmentTemplates = useRef(PpicDepartmentTemplates.fromPartial({}));
  const panelCodeDepartmentTemplateItems = useRef(
    PpicPanelCodeDepartmentTemplateItems.fromPartial({})
  );
  const taskListToShow = useRef([] as MeetingTaskListView[]);

  const openTaskDetailModal = useRef(false);
  const from = useRef(
    makeDateString(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      )
    )
  );
  const to = useRef(makeDateString(new Date()));

  const render = () => {
    refresh((n) => !n);
  };

  const filteredUsers = ctx?.extUsers.filter(user =>
    selectedDept ? `${user.departmentId}` === `${selectedDept}` : true
  );

  const handleInit = () => {
    // fetchTaskLists();
  };

  const usersByFilter = (() => {
    if (selectedUser) {
      return [selectedUser];
    } else if (selectedDept) {
      return ctx?.extUsers
        .filter((u) => `${u.departmentId}` === `${selectedDept}`)
        .map((u) => u.id) ?? [];
    } else {
      return [];
    }
  })();

  const fetchTaskLists = async () => {
    const d = await fetchWorkOrdersProtoMongo({
      apiKey: ctx?.apiKey ?? "",
      extUserIds: usersByFilter,
      from: from.current,
      to: to.current,
    });

    taskLists.current = d;
    render();
  };

  useEffect(() => {
    handleInit();
  }, []);

  const daysSpan = Math.abs(
    (new Date(to.current).getTime() - new Date(from.current).getTime()) /
    86400000
  );

  return (
    <>
      <div className="m-3">
        <div>
          <div>
            <h4>WO Report</h4>
          </div>
        </div>
        <hr className="border border-dark" />

        <div className="d-flex flex-wrap align-items-end">
          <div className="mr-2 mb-1">
            <div>
              <small>
                <strong>
                  From:
                  {/* (
                  {(() => {
                    try {
                      return Intl.DateTimeFormat(
                        navigator.language ?? "en-US",
                        {
                          dateStyle: "full",
                        }
                      ).format(new Date(from.current));
                    } catch (e) {
                      return "";
                    }
                  })()} */}
                </strong>
              </small>
            </div>
            <DateInput
              value={from.current}
              endDate={to.current}
              onChange={(e) => {
                if (e !== "") {
                  from.current = e;
                  render();
                }
              }}

            />

          </div>
          <div className="mr-2 mb-1">
            <div>
              <small>
                <strong>
                  To:
                  {/* (
                  {(() => {
                    try {
                      return Intl.DateTimeFormat(
                        navigator.language ?? "en-US",
                        {
                          dateStyle: "full",
                        }
                      ).format(new Date(to.current));
                    } catch (e) {
                      return "";
                    }
                  })()}
                  ) */}
                </strong>
              </small>
            </div>
            <DateInput
              value={to.current}
              startDate={from.current}
              onChange={(e) => {
                if (e !== "") {
                  to.current = e;
                  render();
                }
              }}

            />

          </div>

          <div style={{ width: 200 }} className="mr-2 mb-1">
            <div>
              <small>
                <strong>
                  Dept:
                </strong>
              </small>
            </div>
            <Select
              placeholder="By Dept..."
              value={selectedDept ? {
                label: ctx?.extDepartments.find(d => d.id === selectedDept)?.name,
                value: ctx?.extDepartments.find(d => d.id === selectedDept)
              } : null}
              options={ctx?.extDepartments.map((u) => ({
                label: `${u.name}`,
                value: u,
              }))}
              onChange={(v) => {
                const val = v as { value: ExtDepartment } | null;
                setSelectedDept(val?.value.id ?? null);
                setSelectedUser(null); // Reset selected user when department changes
              }}
              isClearable
            />

          </div>

          <div style={{ width: 200 }} className="mr-2 mb-1">
            <div>
              <small>
                <strong>
                  User:
                </strong>
              </small>
            </div>
            <Select
              placeholder="By User..."
              value={selectedUser ? {
                label: `${ctx?.extUsers.find(u => u.id === selectedUser)?.username} (${ctx?.extDepartments.find(
                  (d) => `${d.id}` === `${ctx?.extUsers.find(u => u.id === selectedUser)?.departmentId}`
                )?.name
                  })`,
                value: ctx?.extUsers.find(u => u.id === selectedUser)
              } : null}
              options={filteredUsers?.map((u) => ({
                label: `${u.username} (${ctx?.extDepartments.find(
                  (d) => `${d.id}` === `${u.departmentId}`
                )?.name})`,
                value: u,
              }))}
              onChange={(v) => {
                const val = v as { value: ExtUser } | null;
                setSelectedUser(val?.value.id ?? null);
                if (!selectedDept) {
                  setSelectedDept(val?.value.departmentId ?? null);
                }
              }}
              isClearable
            />
          </div>

          <div className="mr-2 mb-1">
            <MyButton
              label="Get Data"
              onClick={() => {
                fetchTaskLists();
              }}
            />
          </div>

          <div className="mb-1">
            <a
              target="_blank"
              href={`${process.env.REACT_APP_MEETING_URL}/woreport?start=${from.current
                }&end=${to.current}&extUserIds=${encodeURIComponent(
                  JSON.stringify(usersByFilter)
                )}&isWoInstaller=null`}
            >
              <MyButton
                variant="success"
                label={<> <CloudDownload fontSize="small" /> XLS</>}
              />
            </a>
          </div>
        </div>
        <hr className="border border-dark" />
        <div>
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {[
                `#`,
                `Dept`,
                `Name`,
                ...[...Array(daysSpan + 1)]
                  .map((_, i) => i)
                  .map((n) => {
                    const d = new Date(
                      new Date(from.current).getFullYear(),
                      new Date(from.current).getMonth(),
                      new Date(from.current).getDate() + n
                    );

                    return `${Intl.DateTimeFormat("en-US", {
                      dateStyle: "short",
                    } as any).format(d)}`;
                  }),
              ].map((h) => (
                <>
                  <th className="bg-dark text-light p-0 m-0">{h}</th>
                </>
              ))}
            </tr>

            {/* User or dept */}
            {usersByFilter.map((u, i) => {
              const fUser = ctx?.extUsers.find((ux) => `${ux.id}` === `${u}`);

              return (
                <>
                  <tr>
                    <td className="border border-dark p-0 m-0">{i + 1}</td>
                    <td className="border border-dark p-0 m-0">
                      {
                        ctx?.extDepartments.find(
                          (d) => `${d.id}` === `${fUser?.departmentId}`
                        )?.name
                      }
                    </td>
                    <td className="border border-dark p-0 m-0">
                      {fUser?.username}
                    </td>
                    {[...Array(daysSpan + 1)]
                      .map((_, i) => i)
                      .map((n) => {
                        const d = new Date(
                          new Date(from.current).getFullYear(),
                          new Date(from.current).getMonth(),
                          new Date(from.current).getDate() + n
                        );

                        const wos = filterWorkOrderByDateRange(
                          taskLists.current,
                          makeDateString(d),
                          makeDateString(d),
                          fUser
                        );

                        const tasks = wos
                          ?.flatMap((tL) => tL.taskList?.meetingTasks)
                          .filter((mt) =>
                            mt?.meetingTaskInCharges.find(
                              (c) => `${c.extUserId}` === `${u}`
                            )
                          );

                        const tasksCompleted = tasks?.filter(
                          (mt) => mt?.status === "COMPLETED"
                        );

                        return (
                          <>
                            <td
                              className="border border-dark p-0 m-0"
                              style={{
                                cursor: "pointer",
                                backgroundColor: getChromaColor(
                                  (tasksCompleted?.length ?? 0) /
                                  (tasks?.length ?? 1)
                                ),
                              }}
                              onClick={() => {
                                openTaskDetailModal.current = true;
                                taskListToShow.current =
                                  filterWorkOrderByDateRange(
                                    taskLists.current,
                                    makeDateString(d),
                                    makeDateString(d),
                                    fUser
                                  ) ?? [];

                                render();
                              }}
                            >
                              {/* {makeDateString(d)} */}
                              <strong>
                                {tasksCompleted?.length}/{tasks?.length}
                              </strong>
                            </td>
                          </>
                        );
                      })}
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
      <Modal
        show={openTaskDetailModal.current}
        onHide={() => {
          openTaskDetailModal.current = false;
          render();
        }}
        size="xl"
      >
        <ModalHeader>
          <div>
            <div>
              <h4>Task Detail </h4>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          {taskListToShow.current.map((tL) => {
            return (
              <>
                <TaskListInfo
                  customers={ctx?.extCustomers}
                  crmCustomers={ctx?.extCrmCustomers}
                  tL={tL}
                  extUsers={ctx?.extUsers ?? []}
                  jobsProto={jobsProto.current ?? PpicJobs.fromPartial({})}
                  machines={machines.current}
                  processTypes={
                    processTypes.current ??
                    PpicSimplifiedProcessTypes.fromPartial({})
                  }
                  rcemDepartments={rcemDepartments.current}
                  integrationModules={integrationModules.current}
                  machinePrograms={machinePrograms.current}
                  departmentTemplates={departmentTemplates.current}
                  panelCodeDepartmentTemplateItems={
                    panelCodeDepartmentTemplateItems.current
                  }
                />
              </>
            );
          })}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};
export default WoReportPage;

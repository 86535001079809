import React, { useContext, useEffect, useRef, useState } from "react";
import {
  TaskListInfo,
  downloadExcel,
  fetchPanelCodeDepartmentTemplateItemsProto,
  fetchTaskListTemplates,
  fetchWorkOrdersProtoMongo,
  filterWorkOrderByDateRange,
  getChromaColor,
  makeDateString,
} from "../../../helpers";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  MeetingMeetingTaskListTemplate,
  MeetingMeetingTaskListTemplates,
  MeetingTaskListView,
  MeetingTaskListsView,
  PpicDepartmentTemplates,
  PpicIntegrationModules,
  PpicJobs,
  PpicMachinePrograms,
  PpicPanelCodeDepartmentTemplateItems,
  PpicRcemDepartments,
  PpicSimplifiedProcessTypes,
} from "../../../masterbigsystem";
import { ExtDepartment, ExtUser, Machine } from "../../../models/model";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { CloudDownload } from "@material-ui/icons";
import { DateInput } from "../../ZGlobalComponents/DateInput";
import MyButton from "../../ZGlobalComponents/MyButton";

const WoTemplateReportPage = () => {
  const ctx = useContext(AppContext);
  const [, refresh] = useState(false);
  const taskLists = useRef(MeetingTaskListsView.fromPartial({}));

  const [selectedDept, setSelectedDept] = useState<number | null>(null);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [selectedTemplates, setSelectedTemplates] = useState<MeetingMeetingTaskListTemplate[]>([]);

  const jobsProto = useRef(PpicJobs.fromPartial({}));
  const machines = useRef([] as Machine[]);
  const processTypes = useRef(PpicSimplifiedProcessTypes.fromPartial({}));
  const rcemDepartments = useRef(PpicRcemDepartments.fromPartial({}));
  const integrationModules = useRef(PpicIntegrationModules.fromPartial({}));
  const templates = useRef(MeetingMeetingTaskListTemplates.fromPartial({}));
  const machinePrograms = useRef(PpicMachinePrograms.fromPartial({}));
  const departmentTemplates = useRef(PpicDepartmentTemplates.fromPartial({}));
  const panelCodeDepartmentTemplateItems = useRef(
    PpicPanelCodeDepartmentTemplateItems.fromPartial({})
  );
  const taskListToShow = useRef([] as MeetingTaskListView[]);

  const openTaskDetailModal = useRef(false);
  const [fromDate, setFromDate] = useState(
    makeDateString(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      )
    )
  );
  const [toDate, setToDate] = useState(makeDateString(new Date()));


  const render = () => {
    refresh((n) => !n);
  };

  const handleInit = () => {
    // fetchTaskLists();
    fetchMeetingMeetingTaskTemplatesData();
  };
  const filteredUsers = ctx?.extUsers.filter(user =>
    selectedDept ? `${user.departmentId}` === `${selectedDept}` : true
  );

  const usersByFilter = (() => {
    if (selectedUser) {
      return [selectedUser];
    } else if (selectedDept) {
      return ctx?.extUsers
        .filter((u) => `${u.departmentId}` === `${selectedDept}`)
        .map((u) => u.id) ?? [];
    } else {
      return [];
    }
  })();

  const fetchTaskLists = async () => {
    const d = await fetchWorkOrdersProtoMongo({
      apiKey: ctx?.apiKey ?? "",
      extUserIds: usersByFilter,
      from: fromDate,
      to: toDate,
    });

    taskLists.current = d;
    render();
  };

  useEffect(() => {
    handleInit();
  }, []);

  const daysSpan = Math.abs(
    (new Date(toDate).getTime() - new Date(fromDate).getTime()) /
    86400000
  );

  const fetchMeetingMeetingTaskTemplatesData = async () => {
    templates.current = await fetchTaskListTemplates({
      apiKey: ctx?.apiKey ?? "",
    });
    render();
  };


  const headers = [
    `#`,
    `Dept`,
    `Name`,
    ...selectedTemplates.length === 0
    ? (() => {
        const taskListNames = taskLists.current.taskLists.reduce((acc, wo) => {
          const taskListName = wo.taskList?.name ? wo.taskList.name : "Others";
          if (!acc.includes(taskListName)) {
            acc.push(taskListName);
          }
          return acc;
        }, [] as string[]).sort((a, b) => {
          if (a === "Others") return 1;
          if (b === "Others") return -1;
          return a.localeCompare(b);
        });

        return taskListNames.flatMap(name => [name, "Work Hours"]);
      })()
    : selectedTemplates.length > 1
      ? selectedTemplates.flatMap(template => [
        template.name ?? "",
        `Work Hours`
      ])
      : selectedTemplates[0]?.items.flatMap((i) => [`${i.name}`, `Work Hours`]) || []
];
  return (
    <>
      <div className="m-3">
        <div>
          <div>
            <h4>WO Template Report</h4>
          </div>
        </div>
        <hr className="border border-dark" />
        <div className="d-flex flex-wrap align-items-end">
          <div className="mr-2 mb-1">
            <div>
              <small>
                <strong>
                  From:
                </strong>
              </small>
            </div>
            <DateInput
              value={fromDate}
              endDate={toDate}
              onChange={(value) => {
                if (value !== "") {
                  setFromDate(value);
                }
              }}
            />
          </div>
          <div className="mr-2 mb-1">
            <div>
              <small>
                <strong>
                  To:
                </strong>
              </small>
            </div>
            <DateInput
              value={toDate}
              startDate={fromDate}
              onChange={(e) => {
                if (e !== "") {
                  setToDate(e);
                  // render();
                }
              }}
            />
          </div>
          <div style={{ width: 200 }} className="mr-2 mb-1">
            <div>
              <small>
                <strong>
                  Dept:
                </strong>
              </small>
            </div>
            <Select
              placeholder="By Dept..."
              value={selectedDept ? {
                label: ctx?.extDepartments.find(d => d.id === selectedDept)?.name,
                value: ctx?.extDepartments.find(d => d.id === selectedDept)
              } : null}
              options={ctx?.extDepartments.map((u) => ({
                label: `${u.name}`,
                value: u,
              }))}
              onChange={(v) => {
                const val = v as { value: ExtDepartment } | null;
                setSelectedDept(val?.value.id ?? null);
                setSelectedUser(null); // Reset selected user when department changes
                taskLists.current = MeetingTaskListsView.fromPartial({});
                render();
              }}
              isClearable
            />
          </div>
          <div style={{ width: 200 }} className="mr-2 mb-1">
            <div>
              <small>
                <strong>
                  User:
                </strong>
              </small>
            </div>
            <Select
              placeholder="By User..."
              value={selectedUser ? {
                label: `${ctx?.extUsers.find(u => u.id === selectedUser)?.username} (${ctx?.extDepartments.find(
                  (d) => `${d.id}` === `${ctx?.extUsers.find(u => u.id === selectedUser)?.departmentId}`
                )?.name
                  })`,
                value: ctx?.extUsers.find(u => u.id === selectedUser)
              } : null}
              options={filteredUsers?.map((u) => ({
                label: `${u.username} (${ctx?.extDepartments.find(
                  (d) => `${d.id}` === `${u.departmentId}`
                )?.name})`,
                value: u,
              }))}
              onChange={(v) => {
                const val = v as { value: ExtUser } | null;
                setSelectedUser(val?.value.id ?? null);
                if (!selectedDept) {
                  setSelectedDept(val?.value.departmentId ?? null);
                }
              }}
              isClearable
            />
          </div>

          <div className="mb-1 mr-2">
            <MyButton
              onClick={() => {
                fetchTaskLists();
              }}
              label="Get Data"
            />
          </div>
          <div className="mb-1">

            <MyButton
              onClick={() => {
                const dateStr = makeDateString(new Date());
                const deptName = ctx?.extDepartments.find(d => d.id === selectedDept)?.name;
                const userName = ctx?.extUsers.find(u => u.id === selectedUser)?.username;
                const templateName = selectedTemplates.length == 1 ? selectedTemplates[0].name : null;

                const parts = ['wo_template_report'];
                if (dateStr) parts.push(dateStr);
                if (deptName) parts.push(deptName);
                if (userName) parts.push(userName);
                if (templateName) parts.push(templateName);

                const fileName = parts.join('_');
                downloadExcel(fileName);
              }}
              variant="success" label={<> <CloudDownload fontSize={"small"} /> XLSX</>}
            />
          </div>

          {/* <div className="mx-2">
            <a
              target="_blank"
              href={`${process.env.REACT_APP_MEETING_URL}/woreport?start=${
                from.current
              }&end=${to.current}&extUserIds=${encodeURIComponent(
                JSON.stringify(usersByFilter)
              )}&isWoInstaller=null`}
            >
              <button className="btn btn-sm btn-success">
                <CloudDownload /> XLS
              </button>
            </a>
          </div> */}
        </div>
        <div className="flex-grow-1  mb-1">
          <div>
            <small>
              <strong>
                Template:
              </strong>
            </small>
          </div>
          <Select
            placeholder="Select templates..."
            options={templates.current.templates.map((t) => ({
              label: t.name ?? "",
              value: t,
            }))}
            isMulti
            closeMenuOnSelect={false}
            value={selectedTemplates.map(t => ({
              label: t.name ?? "",
              value: t,
            }))}
            onChange={(selectedOptions: any) => {
              setSelectedTemplates(selectedOptions ? selectedOptions.map((opt: any) => opt.value) : []);
            }}
          />
          {/* <Select
              placeholder="Select template..."
              options={templates.current.templates.map((t) => ({
                label: t.name ?? "",
                value: t,
              }))}
              isClearable
              onChange={(v) => {
                const val = v as { value: MeetingMeetingTaskListTemplate } | null;
                if (val) {
                  selectedTemplate.current = val.value;
                } else {
                  selectedTemplate.current = null;
                }
                render();
              }}
            /> */}
        </div>
        <hr className="border border-dark" />
        <div className="overflow-auto">
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {headers.map((h, index) => (
                <th
                  key={index}
                  className="bg-dark text-light"
                  style={{ position: "sticky", top: 0 }}
                >
                  {h}
                </th>
              ))}
              {/* {[
                `#`,
                `Dept`,
                `Name`,
                ...(selectedTemplates.length === 0 ? taskLists.current.taskLists.flatMap(wo => [wo.taskList?.name ?? "", "Work Hours"])
                  : selectedTemplates.length > 1
                    ? selectedTemplates.flatMap(template => [
                      template.name ?? "",
                      `Work Hours`
                    ])
                    : selectedTemplates[0]?.items.flatMap((i) => [`${i.name}`, `Work Hours`]) || []
                ),
              ].map((h) => (
                <th
                  className="bg-dark text-light"
                  style={{ position: "sticky", top: 0 }}
                >
                  {h}
                </th>
              ))} */}
            </tr>

            {/* User or dept */}
            {usersByFilter.map((u, i) => {
              const fUser = ctx?.extUsers.find((ux) => `${ux.id}` === `${u}`);

              return (
                <>
                  <tr>
                    <td className="border border-dark p-0 m-0">{i + 1}</td>
                    <td className="border border-dark p-0 m-0">
                      {
                        ctx?.extDepartments.find(
                          (d) => `${d.id}` === `${fUser?.departmentId}`
                        )?.name
                      }
                    </td>
                    <td className="border border-dark p-0 m-0">
                      {fUser?.username}
                    </td>
                    {selectedTemplates.length === 0 ? (
                      // When no template is selected,
                      Object.entries(
                        taskLists.current.taskLists.reduce((acc, taskList) => {
                          const userTasks = taskList.taskList?.meetingTasks.filter((mt) =>
                            mt?.meetingTaskInCharges.find((ic) => `${ic.extUserId}` === `${u}`)
                          );

                          const totalHours = (userTasks ?? []).reduce(
                            (acc, mt) => acc + (mt?.durationMins ?? 0),
                            0.0
                          ) / 60;

                          const taskListName = taskList.taskList?.name ? taskList.taskList.name : "Others";

                          if (!acc[taskListName]) {
                            acc[taskListName] = { count: 0, hours: 0 };
                          }

                          acc[taskListName].count += userTasks?.length ?? 0;
                          acc[taskListName].hours += totalHours;

                          return acc;
                        }, {} as Record<string, { count: number; hours: number }>)

                      ).sort(([keyA], [keyB]) => {
                        if (keyA === "Others") return 1;
                        if (keyB === "Others") return -1;
                        return keyA.localeCompare(keyB);
                      }).map(([taskName, { count, hours }]) => (
                        <React.Fragment key={taskName}>
                          <td className="border border-dark">{count}</td>
                          <td className="border border-dark">{hours.toFixed(2)}</td>
                        </React.Fragment>
                      ))
                    ) : selectedTemplates.length > 1 ? (
                      // For multiple templates, show one count and one work hours column per template
                      selectedTemplates.map(template => {
                        const templateTasks = taskLists.current.taskLists
                          .flatMap((tL) => tL.taskList?.meetingTasks)
                          .filter((mt) =>
                            mt?.meetingTaskInCharges.find((ic) => `${ic.extUserId}` === `${u}`) &&
                            template.items.some(item => `${item.masterJavaBaseModel?.id}` === `${mt.woTemplateItemId}`)
                          );

                        const totalHours = templateTasks.reduce(
                          (acc, mt) => acc + (mt?.durationMins ?? 0),
                          0.0
                        ) / 60;

                        return (
                          <React.Fragment key={template.masterJavaBaseModel?.id}>
                            <td className="border border-dark">
                              {templateTasks.length}
                            </td>
                            <td className="border border-dark">
                              {totalHours.toFixed(2)}
                            </td>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      // For single template, keep existing item-by-item display
                      selectedTemplates[0]?.items.map((t) => {
                        const detectedTasks = taskLists.current.taskLists
                          .flatMap((tL) => tL.taskList?.meetingTasks)
                          .filter(
                            (mt) =>
                              mt?.meetingTaskInCharges.find(
                                (ic) => `${ic.extUserId}` === `${u}`
                              ) &&
                              `${mt.woTemplateItemId}` === `${t.masterJavaBaseModel?.id}`
                          );
                        const hrs =
                          detectedTasks.reduce(
                            (acc, mt) => acc + (mt?.durationMins ?? 0),
                            0.0
                          ) / 60;

                        return (
                          <React.Fragment key={t.masterJavaBaseModel?.id}>
                            <td className="border border-dark">
                              {detectedTasks.length}
                            </td>
                            <td className="border border-dark">
                              {hrs.toFixed(2)}
                            </td>
                          </React.Fragment>
                        );
                      })
                    )}
                  </tr>
                </>
              );
            })}
            <tr>
              <td className="border border-dark" colSpan={3}>
                <strong>Grand Total</strong>
              </td>
              {selectedTemplates.length === 0 ? (
                // When no template is selected, show totals by task list name
                Object.entries(
                  taskLists.current.taskLists.reduce((acc, taskList) => {
                    const taskListName = taskList.taskList?.name ? taskList.taskList.name : "Others";
                    const tasks = taskList.taskList?.meetingTasks ?? [];

                    if (!acc[taskListName]) {
                      acc[taskListName] = { count: 0, hours: 0 };
                    }

                    acc[taskListName].count += tasks.length;
                    acc[taskListName].hours += tasks.reduce(
                      (sum, mt) => sum + (mt?.durationMins ?? 0),
                      0.0
                    ) / 60;

                    return acc;
                  }, {} as Record<string, { count: number; hours: number }>)

                ).sort(([keyA], [keyB]) => {
                  if (keyA === "Others") return 1;
                  if (keyB === "Others") return -1;
                  return keyA.localeCompare(keyB);
                }).map(([taskName, { count, hours }]) => (
                  <React.Fragment key={taskName}>
                    <td className="border border-dark">
                      <strong>{count}</strong>
                    </td>
                    <td className="border border-dark">
                      <strong>
                        {Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(hours)}
                      </strong>
                    </td>
                  </React.Fragment>
                ))
              ) : selectedTemplates.length > 1 ? (
                // For multiple templates, show one total count and hours per template
                selectedTemplates.map(template => {
                  const templateTasks = taskLists.current.taskLists
                    .flatMap((tL) => tL.taskList?.meetingTasks)
                    .filter((mt) =>
                      template.items.some(item => `${item.masterJavaBaseModel?.id}` === `${mt?.woTemplateItemId}`)
                    );

                  const totalHours = templateTasks.reduce(
                    (acc, mt) => acc + (mt?.durationMins ?? 0),
                    0.0
                  ) / 60;

                  return (
                    <React.Fragment key={template.masterJavaBaseModel?.id}>
                      <td className="border border-dark">
                        <strong>{templateTasks.length}</strong>
                      </td>
                      <td className="border border-dark">
                        <strong>
                          {Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(totalHours)}
                        </strong>
                      </td>
                    </React.Fragment>
                  );
                })
              ) : (
                // For single template, keep existing item-by-item totals
                selectedTemplates[0]?.items.map((t) => {
                  const detectedTasks = taskLists.current.taskLists
                    .flatMap((tL) => tL.taskList?.meetingTasks)
                    .filter(
                      (mt) =>
                        `${mt?.woTemplateItemId}` === `${t?.masterJavaBaseModel?.id}`
                    );
                  const hrs =
                    detectedTasks.reduce(
                      (acc, mt) => acc + (mt?.durationMins ?? 0),
                      0.0
                    ) / 60;
                  return (
                    <React.Fragment key={t.masterJavaBaseModel?.id}>
                      <td className="border border-dark">
                        <strong>{detectedTasks.length}</strong>
                      </td>
                      <td className="border border-dark">
                        <strong>
                          {Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(hrs)}
                        </strong>
                      </td>
                    </React.Fragment>
                  );
                })
              )}
            </tr>
          </table>
        </div>
      </div>
      <Modal
        show={openTaskDetailModal.current}
        onHide={() => {
          openTaskDetailModal.current = false;
          render();
        }}
        size="xl"
      >
        <ModalHeader>
          <div>
            <div>
              <h4>Task Detail </h4>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          {taskListToShow.current.map((tL) => {
            return (
              <>
                <TaskListInfo
                  customers={ctx?.extCustomers}
                  crmCustomers={ctx?.extCrmCustomers}
                  tL={tL}
                  extUsers={ctx?.extUsers ?? []}
                  jobsProto={jobsProto.current ?? PpicJobs.fromPartial({})}
                  machines={machines.current}
                  processTypes={
                    processTypes.current ??
                    PpicSimplifiedProcessTypes.fromPartial({})
                  }
                  rcemDepartments={rcemDepartments.current}
                  integrationModules={integrationModules.current}
                  machinePrograms={machinePrograms.current}
                  departmentTemplates={departmentTemplates.current}
                  panelCodeDepartmentTemplateItems={
                    panelCodeDepartmentTemplateItems.current
                  }
                />
              </>
            );
          })}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};
export default WoTemplateReportPage;

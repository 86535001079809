import React, { useEffect, useRef } from 'react';

interface DateInputProps {
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  startDate?: string | null;
  endDate?: string | null;
  
}

export const DateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  // onBlur,
  placeholder = 'Select date',
  disabled = false,
  className = '',
  startDate = null,
  endDate = null,
}) => {
  return (
    <input
      type="date"
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      style={{ paddingTop: '0.325rem', paddingBottom: '0.325rem' }}
      min={startDate || undefined}
      max={endDate || undefined}
      className={`
        w-full px-2 
        border border-gray-300 rounded
        disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed
        ${className}
      `}
    />
  );
};